import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { map, pathOr } from 'ramda';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { FormControlLabel } from '@material-ui/core';
import * as Routes from 'Routes';
import { authenticityToken } from 'utils/FetchHelpers';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import FormGroup from 'components/FormGroup';
import Icon from 'components/Icon';
import TextField from 'components/TextField';
import Flash from 'components/Flash';
import SocialAuthButtons from 'components/SocialAuthButtons';

import styles from './styles';

class SignIn extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
  };

  state = {
    email: pathOr('', ['email'], this.props.user),
    password: '',
    rememberMe: 0,
    notifications: gon.flash,
  };

  handleChange = field => e => {
    this.setState({ [field]: e.target.value });
  };

  handleCheck = () => {
    this.setState({ rememberMe: this.state.rememberMe === 1 ? 0 : 1 });
  };

  handleSubmit = e => {
    const form = e.target.elements;
    form.authenticity_token.value = authenticityToken();
  };

  renderFlash = flash => <Flash key={flash.message} flash={flash} />;

  render() {
    const { classes } = this.props;
    const { email, password, rememberMe, notifications } = this.state;

    return (
      <>
        <Helmet>
          <title>Log in to LearnPlatform</title>
        </Helmet>
        <div className={classes.formContainer}>
          <div className={classes.form}>
            <Icon icon="welcome" />
            <div className={classes.title}>Welcome to LearnPlatform!</div>

            {map(this.renderFlash, notifications)}

            <form action={Routes.new_user_session_path()} method="post" onSubmit={this.handleSubmit}>
              <input type="hidden" name="authenticity_token" />
              <div className={classes.textFields}>
                <FormGroup>
                  <TextField
                    data-node="user_email_field"
                    name="user[email]"
                    onChange={this.handleChange('email')}
                    value={email}
                    placeholder="Enter email address"
                    autoComplete="username"
                    id="email"
                    label="Email"
                    variant="filled"
                    helperText="Required*"
                    className={classes.textField}
                  />
                </FormGroup>
                <FormGroup className={classes.passwordTextField}>
                  <TextField
                    data-node="user_password_field"
                    placeholder="Enter password"
                    name="user[password]"
                    type="password"
                    onChange={this.handleChange('password')}
                    value={password}
                    autoComplete="current-password"
                    id="password"
                    label="Password"
                    variant="filled"
                    helperText="Required*"
                    className={`${classes.textField} ${classes.passwordTextField}`}
                  />
                  <div className={classes.forgotPassword}>
                    <Button
                      variant="text"
                      href={Routes.new_user_password_path()}
                      className={classes.linkForgotPassword}
                    >
                      Forgot Password
                    </Button>
                  </div>
                </FormGroup>
                <FormControlLabel
                  control={<Checkbox onChange={() => this.handleCheck()} name="user[remember_me]" value={rememberMe} />}
                  className={classes.checkbox}
                  label="Remember Me"
                />
              </div>

              <div className={classes.buttons}>
                <div className={classes.button}>
                  <Button href={Routes.new_user_registration_path()} size="large" color="bordered" fullWidth>
                    Create Account
                  </Button>
                </div>
                <div className={classes.button}>
                  <Button data-node="login_button" size="large" type="submit" fullWidth>
                    Login
                  </Button>
                </div>
              </div>
            </form>

            <SocialAuthButtons />
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(SignIn);
